/** Пол. */
export enum Sex {
    /** Мужчина */
    Man = 'Man',

    /** Женщина */
    Woman = 'Woman'
}

// типы ролей
export const RoleTypes  = {
    Undefined: 'Undefined',                // Неизвестный статус
    Administrator: 'Administrator',        // Администратор
    Client: 'Client',                      // Клиент
    SettlementAdministrator: 'SettlementAdministrator', // Администратор заселения
    SettlementManager: 'SettlementManager'  // Менеджер заселения
} as const 

export type RoleType = typeof RoleTypes[keyof typeof RoleTypes];