import { jwtDecode } from "jwt-decode";
import { defineStore } from 'pinia';
import { useUser } from "~/composables/user/useUser";
import { AuthTokenCookieName } from "~/types/consts/auth";
import { useSafe } from "~/composables/shared/useSafe";
import { useAuthorization } from "~/composables/auth/useAuthorization";
import { RoleTypes, type RoleType } from "~/types/user/enum";

const { getUser } = useUser();
const { refreshToken, redirectToLogoutUrl } = useAuthorization();

export const useAuthStore = defineStore('auth', () => {
    const userId = ref<string | undefined>(undefined);
    const user = ref<IUserData | undefined>(undefined);

    const isAuthorized = computed(() => !!userId.value);
    const isClient = computed(() => user.value?.role === RoleTypes.Client);
    const isAdministrator = computed(() => user.value?.role === RoleTypes.Administrator);
    const isSettlementAdministrator = computed(() => user.value?.role === RoleTypes.SettlementAdministrator);
    const isSettlementManager = computed(() => user.value?.role === RoleTypes.SettlementManager);
    const isSettlementOperators = computed(() => isSettlementAdministrator.value || isSettlementManager.value);
    const isOperator = computed(() => isAdministrator.value || isSettlementOperators.value);

    /* Инициализирует хранилище. */
    const init = async (): Promise<void> => {
        if (userId.value) {
            return;
        }

        let token = useCookie(AuthTokenCookieName);
        if (!token.value) {
            const isRefreshed = await refreshToken();
            if (!isRefreshed) {
                return;
            }

            token = useCookie(AuthTokenCookieName);
            if (!token.value) {
                redirectToLogoutUrl();
                return;
            }
        }

        await setUser();
    };

    /* Пытается установить данные пользователя из токена */
    const setUser = async (): Promise<string | undefined> => {
        const { tryAction } = useSafe();
        let retrievedUserId: string | undefined;

        await tryAction(async () => {
            const token = useCookie(AuthTokenCookieName);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const json: any = jwtDecode(token.value!);
            retrievedUserId = json.sub;
            const fetchedUser = await getUser(retrievedUserId!);

            if (fetchedUser?.data) {
                userId.value = retrievedUserId;
                user.value = fetchedUser.data!;
            }
        });

        return retrievedUserId;
    };

    // Возвращает тип роли пользователя.
    const getRoleType = () : RoleType => {
        if(isAdministrator.value) {
            return RoleTypes.Administrator;
        }
        if(isSettlementAdministrator.value) {
            return RoleTypes.SettlementAdministrator;
        }
        if(isSettlementManager.value) {
            return RoleTypes.SettlementManager;
        }
        return RoleTypes.Client;
    }

    /* При разлогине очищает хранилище. */
    const logout = (): void => {
        userId.value = undefined;
        user.value = undefined;
    };

    return {
        userId,
        user,
        isAuthorized,
        isClient,
        isOperator,
        isAdministrator,
        isSettlementAdministrator,
        isSettlementManager,
        isSettlementOperators,
        init,
        setUser,
        getRoleType,
        logout,
    };
});
