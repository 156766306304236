import { useAuthStore } from '~/stores/auth/useAuthStore';
import { useApi } from '~/composables/shared/useApi'
import { useSafe } from '~/composables/shared/useSafe';
import type { Result } from "~/types/common/result";

const { tryAction, trySilentAction } = useSafe();
const { getSecurityAbsoluteUrl, post } = useApi();

/** Методы авторизации. */
export const useAuthorization = () => {
    /** Разлогин. */
    const signOut = async () : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl('/identify/logout');
            await post(url, { });

            resetAuth();
        });
    }

    /** Обновление токена */
    const refreshToken = async () => {
        const result = await trySilentAction(async () => {
            const url = getSecurityAbsoluteUrl('/identify/token/refresh');
            await $fetch(url, {
                server: false,
                credentials: 'include'
            });
        });
        return !result.isError;
    }

    const resetAuth = () : void => {
        const store = useAuthStore();
        store.logout();

        redirectToLogoutUrl();
    }

    /** Переброс на страницу для разлогиненных. */
    const redirectToLogoutUrl = () : void => {
        const config = useRuntimeConfig();
        navigateTo(config.public.logoutRedirectUrl, { external: true });
    }

    return {
        signOut,
        refreshToken,
        redirectToLogoutUrl
    }
}