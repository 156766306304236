import { defineNuxtPlugin } from '#app/nuxt'
import { LazyMonitoring, LazyRealtyList, LazyAcceptanceDialog, LazyAcceptanceDialogOwners, LazyAcceptances, LazyHouseFilter, LazyHouseGrid, LazyImportRealtyExcelButton, LazyRealtyFilter, LazyRealtyGrid, LazyObjectCard, LazyAppointment, LazySteps, LazySuccess, LazyAcceptanceCompleted, LazyAcceptanceScheduled, LazyAcceptanceScheduledCancel, LazyAcceptanceScheduledSuccess, LazyDate, LazyDateDialog, LazyPersonalData, LazyPersonalDataOwner } from '#components'
const lazyGlobalComponents = [
  ["Monitoring", LazyMonitoring],
["RealtyList", LazyRealtyList],
["AcceptanceDialog", LazyAcceptanceDialog],
["AcceptanceDialogOwners", LazyAcceptanceDialogOwners],
["Acceptances", LazyAcceptances],
["HouseFilter", LazyHouseFilter],
["HouseGrid", LazyHouseGrid],
["ImportRealtyExcelButton", LazyImportRealtyExcelButton],
["RealtyFilter", LazyRealtyFilter],
["RealtyGrid", LazyRealtyGrid],
["ObjectCard", LazyObjectCard],
["Appointment", LazyAppointment],
["Steps", LazySteps],
["Success", LazySuccess],
["AcceptanceCompleted", LazyAcceptanceCompleted],
["AcceptanceScheduled", LazyAcceptanceScheduled],
["AcceptanceScheduledCancel", LazyAcceptanceScheduledCancel],
["AcceptanceScheduledSuccess", LazyAcceptanceScheduledSuccess],
["Date", LazyDate],
["DateDialog", LazyDateDialog],
["PersonalData", LazyPersonalData],
["PersonalDataOwner", LazyPersonalDataOwner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
